import React from "react";
import { graphql } from "gatsby";
import GatsbyLink from "../components/GatsbyLink";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { dateI18n } from "@wordpress/date";
import NewsHeaderImage from "../images/news-header.jpg";
import { AcfComponent } from "./componentLoader";
import { ImageAndContentBlock } from "..//components/Acf";

export const BlogPostTemplate = ({
  content,
  categories,
  title,
  date,
  blogSlug,
  image,
  excerpt,
  layout,
  location,
  pageContext,
}) => {
  const contentLayout = layout?.filter(
    (acf) => !acf.__typename.includes("ImageAndContentBlock")
  );

  const outerLayout = layout?.filter((acf) =>
    acf.__typename.includes("ImageAndContentBlock")
  ); // Add more outer layouts here if needed

  return (
    <>
      <section className="post-header">
        <div className="wrap">
          <div className="image-container">
            <ImagePass src={image} />
          </div>
          <div className="content">
            {categories.legnth !== null && (
              <span className="eyebrow">{categories[0].name}</span>
            )}
            <h2>{decodeEntities(title)}</h2>
            <RenderShortcode content={excerpt} />
          </div>
        </div>
      </section>
      <section className="post-content">
        <div className="wrap">
          {contentLayout &&
            contentLayout.map((item, index) => {
              if (!item.__typename) return null;
              const layoutComponentName = item.__typename.replace(
                "WordPressAcf_",
                ""
              );
              return (
                <AcfComponent
                  key={index}
                  index={index}
                  componentName={layoutComponentName}
                  item={item}
                  location={location}
                  pageContext={pageContext}
                />
              );
            })}
          <div className="post-navigation">
            <GatsbyLink to="/news/" className="button--dark">
              Back to news
            </GatsbyLink>
          </div>
        </div>
      </section>
      {outerLayout &&
        outerLayout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          return (
            <AcfComponent
              key={index}
              index={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
            />
          );
        })}
    </>
  );
};

const BlogPost = (props) => {
  const { data, location, pageContext } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
    acf,
  } = post;

  const { blogSlug, date_format } = wordpressWpSettings;
  const dateFormatted = date && date_format && dateI18n(date_format, date);

  const { layout } = acf;

  const bannerImage =
    layout.find((field) => field.__typename.includes("postInformation"))
      ?.bannerImage ||
    featured_media ||
    NewsHeaderImage;

  const postExcerpt =
    layout.find((field) => field.__typename.includes("postInformation"))
      ?.postDescription ||
    excerpt ||
    "";
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={bannerImage}
        location={location}
      />
      <Header header="News" image={bannerImage} compact />
      <BlogPostTemplate
        layout={layout}
        categories={categories}
        title={title}
        date={dateFormatted}
        image={featured_media}
        excerpt={postExcerpt}
        location={location}
        pageContext={pageContext}
      />
    </>
  );
};

export default Previewable(BlogPost, "post");

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        layout: layout_post {
          __typename
          ...TwoColumnQuery
          ...PostInformationQuery
          ...ImageAndContentBlockQuery
          ...FullWidthCarouselQuery
          ...ContentAndIconListQuery
        }
      }
      categories {
        name
        slug
        path
      }
      author {
        name
        slug
        path
        avatar_urls {
          wordpress_48
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
